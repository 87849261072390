
#hide-layers {
    cursor: pointer;
    display: inline-block;
    font-family: Avenir;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    color: #9b9b9b;
}
