
.map-view {
    .bottom-panel {
        width: 100%;
        bottom: 0;
    }

    .location_indicator {
        padding-top: 30px;
    }
}
